import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "levantando-el-velo",
      "style": {
        "position": "relative"
      }
    }}>{`Levantando el velo`}<a parentName="h1" {...{
        "href": "#levantando-el-velo",
        "aria-label": "levantando el velo permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "porqué",
      "style": {
        "position": "relative"
      }
    }}>{`Porqué`}<a parentName="h2" {...{
        "href": "#porqu%C3%A9",
        "aria-label": "porqué permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`A menudo puede parecer que las personas que escriben "smart contracts" (contratos inteligentes) son una raza extraña de súper codificadores sombríos que no se parecen en nada a mi. Puedo entender que Ethereum es esta "máquina mundial", y que cualquiera puede implementar un contrato inteligente sin permiso, y que todo está conectado de una manera que parece emocionar a los nerds, pero la brecha entre "ellos" y yo parece tan grande. Me pone ansioso y no me gusta sentirme ansioso o inepto.`}</p>
    <p>{`Entonces, vamos a cometer un montón de errores juntos. Estaremos frustrados y confundidos juntos. Querremos lanzar algo juntos a nuestras pantallas. Y luego, cuando superemos esto juntos, de repente te darás cuenta de que no existen "ellos" y que cada "desarrollador" es simplemente una persona común y corriente que pasa la mayor parte de su tiempo preguntándose qué diablos está pasando con su computadora. Los buenos desarrolladores son simplemente aquellos que tienen sentido del humor y cierto tipo de valor y determinación. También saben cómo hacer preguntas claras que los lleven al código que más necesitan copiar y pegar en su propio trabajo.`}</p>
    <h2 {...{
      "id": "preparación",
      "style": {
        "position": "relative"
      }
    }}>{`Preparación`}<a parentName="h2" {...{
        "href": "#preparaci%C3%B3n",
        "aria-label": "preparación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Instala `}<a parentName="li" {...{
          "href": "https://metamask.io"
        }}>{`MetaMask`}</a>{` y crea una cuenta Ethereum.`}</li>
      <li parentName="ol">{`Cambie su red (en la parte superior de la ventana emergente del navegador) a Sepolia Test Network.`}</li>
      <li parentName="ol">{`Regístrese en Alchemy para obtener Sepolia ETH.`}</li>
      <li parentName="ol">{`Comprueba que tienes una versión actualizada de Chrome, Firefox o Brave.`}</li>
    </ol>
    <p>{`NOTA: En el momento en que se grabaron los videos de este gremio, se estaba utilizando Rinkeby Testnet. Esa red de prueba ahora ha `}<a parentName="p" {...{
        "href": "https://www.alchemy.com/overviews/rinkeby-testnet"
      }}>{`quedado obsoleta`}</a>{` por la Fundación Ethereum. Siempre que se mencione 'Rinkeby Testnet' o 'Rinkeby ETH' en las grabaciones, simplemente traduce esto en tu mente como 'Sepolia Testnet' o 'Sepolia ETH'.`}</p>
    <h2 {...{
      "id": "aplicación",
      "style": {
        "position": "relative"
      }
    }}>{`Aplicación`}<a parentName="h2" {...{
        "href": "#aplicaci%C3%B3n",
        "aria-label": "aplicación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En esta primera lección, enfrentaremos 3 desafíos de Ethernaut: Fallback, Fallout y Token. Para hacer eso, primero necesitaremos cubrir algunos conceptos básicos.`}</p>
    <ol>
      <li parentName="ol">{`¿Qué es una consola web?`}</li>
      <li parentName="ol">{`¿Cómo funciona MetaMask?`}</li>
      <li parentName="ol">{`¿Cómo se conecta una página web a Ethereum?`}</li>
      <li parentName="ol">{`¿Dónde está Ethereum? (y preguntas asociadas: ¿Quién soy yo? ¿Qué es la vida? ¿Por qué todo esto?)`}</li>
      <li parentName="ol">{`Un breve destello de javascript, promesas, objetos y otras delicias.`}</li>
      <li parentName="ol">{`Los desafíos reales enumerados anteriormente.`}</li>
    </ol>
    <h2 {...{
      "id": "resumen",
      "style": {
        "position": "relative"
      }
    }}>{`Resumen`}<a parentName="h2" {...{
        "href": "#resumen",
        "aria-label": "resumen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En esta sesión habrás aprendido:`}</p>
    <ol>
      <li parentName="ol">{`Cómo levantar el velo de tu navegador y observar su mecánica interna a través de la consola web.`}</li>
      <li parentName="ol">{`Cómo emitir instrucciones, es decir, cómo puedes codificar, directamente desde tu consola sin tener que configurar ningún software extraño del que un desarrollador extraño te habló una vez.`}</li>
      <li parentName="ol">{`Qué es y qué hace MetaMask y un “proveedor web3 inyectado”.`}</li>
      <li parentName="ol">{`Cómo se ve un contrato inteligente.`}</li>
      <li parentName="ol">{`Cómo interactuar con contratos inteligentes desde la comodidad de tu hogar.`}</li>
      <li parentName="ol">{`Algunas de las formas básicas en que se puede manipular un contrato inteligente y por qué.`}</li>
      <li parentName="ol">{`Algunas de las formas básicas en que un contrato inteligente puede manipularte y por qué.`}</li>
    </ol>
    <h2 {...{
      "id": "grabación",
      "style": {
        "position": "relative"
      }
    }}>{`Grabación`}<a parentName="h2" {...{
        "href": "#grabaci%C3%B3n",
        "aria-label": "grabación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/3f9mu_6LRD8?start=21" mdxType="Video" />
    <p><img parentName="p" {...{
        "src": "/images/build/flammarion_engraving.jpg",
        "alt": "Flammarion Engraving"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      